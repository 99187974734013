import React from 'react'

const Resources = () => {
  return (
    <div id='resources'>
        <div className="container" id='forms-and-documents'>
            <table id='forms-and-documents-table'>
                <thead>
                    <tr>
                        <td className='center'>FORMS AND DOCUMENTS</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a href='forms_and_documents/lgn_fall2024_schedule.pdf' target='_blank'>FALL 2024 SCHEDULE</a></td>
                    </tr>
                    <tr>
                        <td><a href='forms_and_documents/usys-medical-release.pdf' target='_blank'>MEDICAL RELEASE FORM (USYS)</a></td>
                    </tr>
                    <tr>
                        <td><a href="forms_and_documents/hardship-application.pdf" target="_blank">HARDSHIP APPLICATION</a></td>
                    </tr>
                    {/* <tr>
                        <td><a href="forms_and_documents/game_change_request_edp_and_lijsl.pdf" target="_blank">GAME CHANGE REQUEST</a></td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Resources